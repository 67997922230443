<template>
  <div class="page">
    <h1>
      <w-icon>mdi mdi-magnify</w-icon>
      より詳しく
    </h1>
    <div class="content">
      <h2 id="map">
        <w-icon>mdi mdi-map-outline</w-icon>
        長狭平野の 実際の 地形
      </h2>
      <w-divider/>
      <p>
        &emsp;長狭平野の実際の地形を上空から見てみましょう。
      </p>
      <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%" height="400" src="https://maps.gsi.go.jp/?hc=hic#10/35.123278/140.046844/&base=ort&ls=ort%7Chillshademap%7Canaglyphmap_color&blend=00&disp=111&vs=c1g1j0h0k0l0u0t0z0r0s0m0f1"></iframe>
      <ul class="reference no-point">
        <li class="text-right">出典：国土地理院ウェブサイト（https://maps.gsi.go.jp/）</li>
      </ul>
      <p>
        &emsp;いかがですか？
        北と南に並んでそびえ立った山々との間に、東西にむかって細長く伸びている地形があります、
        これぞまさしく、読んで字のごとく、<b>長狭平野</b>でございます。
        <router-link to="/service/nagasa-mai/secret/">
          美味しく育つひみつ
        </router-link>
        で公開しましたように、これほどまでに<u>稲を育てるのにうってつけな特徴</u>を持った平野は、
        他を探してもなかなかあるものではないということを、どうぞ、ご注目されてください。
      </p>
      <h2 id="actual">
        <w-icon>mdi mdi-sprout</w-icon>
        実際の 稲とお米の 様子
      </h2>
      <w-divider/>
      <p>
        <w-flex class="justify-space-evenly align-center">
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/actual/ine.jpg"></w-image>
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/actual/inekari.jpg"></w-image>
        </w-flex>
        &emsp;左の写真は田植え前の<b>苗</b>の様子です。
        太陽の光をめいっぱいに浴びた苗からは、青々とした力強さを感じます。
        また、右の写真は<b>稲刈り</b>の様子です。稲の葉は力強く天に伸び、大粒な稲穂はこうべを垂れるように下を向いている様子が、
        おわかりいただけると思います。
        <w-flex class="justify-space-evenly align-center">
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/actual/inaho.jpg"></w-image>
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/actual/genmai_hand.jpg"></w-image>
        </w-flex>
        &emsp;左の写真は稲刈り目前の<b>稲穂</b>の様子で、右の写真は刈り取った稲から精製した<b>玄米</b>の様子です。
        ずっしりとした大きな稲穂のなかに、ツヤツヤとした大きな玄米の粒が入っていることを、
        実感していただけることと思います。
      </p>
      <h2 id="natural">
        <w-icon>mdi mdi-hand-heart-outline</w-icon>
        本来の味を お渡ししたい
      </h2>
      <w-divider/>
      <p>
        &emsp;お米屋さんは、いろいろな農家さんからお米を買い取り、
        味と品質が全体で一定となるように、大型の乾燥機によってお米を混ぜ合わせます。
        そうすると、毎年 特に美味しく実る農家さんのお米であっても、お米屋さんを通して市場に流してもらうと、
        どうしても本来の味に比べて少し薄くぼやけてしまった味の商品が店頭に並ぶこととなります。
        <br>&emsp;そこで、我々 <b>米農家</b>が、お客様と直接やり取りをすることで、
        <u>自慢の味をそのままのかたちで、安くお客様にお渡しする</u>ことができるようになります。
        この度、房総半島の鴨川市へ遊びに来られたお客様に、お米のお持ち帰りサービスを始めました。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/takeout/">
          お持ち帰りサービス
          <w-icon>mdi mdi-car-hatchback</w-icon>
        </router-link>
      </p>
      <h2 id="low-cost">
        <w-icon>mdi mdi-transfer-down</w-icon>
        本当に安い？
      </h2>
      <w-divider/>
      <p>
        &emsp;お安く提供しています。
        <b>”長狭米”</b>でGoogle検索を行いますと、竹ノ内米店さんや北村商店さんや房の駅さんのオンラインショップ
        （いずれも素晴らしいお店やさんです）や、
        更には
        <a href="https://search.rakuten.co.jp/search/mall/%E9%95%B7%E7%8B%AD%E7%B1%B3/" target="_blank" rel="noopener noreferrer">
          楽天市場
        </a>
        さんの価格なども見ることができますので、ぜひご自分で比較検討をされてみてください。
        きっと<b>何割も</b><u>お安いことにお気づきになられる</u>ことと思います。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/takeout/">
          お持ち帰りサービス
          <w-icon>mdi mdi-car-hatchback</w-icon>
        </router-link>
      </p>
      <h2 id="recommend">
        <w-icon>mdi mdi-hand-okay</w-icon>
        おすすめ
      </h2>
      <w-divider/>
      <p>
        &emsp;しっかりとした甘味と冷めても美味しさを損なわない長狭米は、
        古来よりお寿司屋さんやおにぎりとして重宝されてきました。
        現代では、特に<b>お弁当</b>を持って通勤・通学される方がいらっしゃるご家庭にとって、
        <u>冷めても美味しい長狭米</u>は、人気なお米の種類のひとつとなっています。
        <br>&emsp;また、低価格化が進んだ家庭用の精米機で、玄米を<b>少量づつ精米</b>して召し上がると、
        いつでも最高に美味しいごはんを食卓に並べることができます。
        ご自宅近くのコイン精米所で１０ｋｇで￥１００程度で精米することもできます。
        また、<u>精米機のレンタル</u>も人気がではじめていますし、
        精米した際に出る<b>米ぬか</b>は安心安全で無料な<u>ガーデニングの有機肥料</u>となります。
        <br>&emsp;更に、<b>発芽玄米</b>が炊ける炊飯器なら、<u>美容にも健康にも良い酵素たっぷりのご飯</u>を、
        毎日いただくことができますので、ぜひ玄米でのお求めを ご検討されてみてください。
      </p>
      <h2>
        <w-icon>mdi mdi-mother-heart</w-icon>
        御縁の和
      </h2>
      <w-divider/>
      <p>
        <w-flex class="justify-space-evenly align-center">
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/goen/give_stone_v0.1.1.jpg"></w-image>
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/goen/give_board+top100_stone_v0.1.1.jpg"></w-image>
        </w-flex>
        &emsp;明治４年、長狭平野の中央に位置する主基地区が
        <b>明治天皇の御即位大嘗祭</b>へ献上するためのお米をつくる斎田として選ばれ、
        神殿などが建ち並び、交代で役人が警備していたと伝えられています。
        その後、主基斎田で育てられた稲穂と新米で醸造した白酒（しろき）が、
        毎年 明治天皇がまつられている<b>明治神宮</b>に奉献され続けております。
        また、<b>日本の米づくり百選</b>にも選ばれました。
        <w-flex class="justify-space-evenly align-center">
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/goen/royal-airport_clock_v0.1.1.jpg"></w-image>
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/goen/royal-airport_thank_v0.1.1.jpg"></w-image>
        </w-flex>
        <br>&emsp;ある日、<b>ロイヤル航空</b>のヘリコプターがエンジントラブルによって急降下し、
        不時着する場所として選んだのが、我が米農家のひときわ目立つほどのとても大きな田んぼのど真ん中でした。
        その際に、人命救助と機体の回収を率先して行ったのが、我が米農家の<b>先代</b>であり、
        頂いた感謝状と記念の時計を、今でもありがたく飾らさせていただいております。
      </p>
      <h2 id="thank">
        <w-icon>mdi mdi-hands-pray</w-icon>
        御礼品
      </h2>
      <w-divider/>
      <p>
        <w-flex class="justify-space-evenly align-center">
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/present/cup_whale_v0.1.1.jpg"></w-image>
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs3" src="/image/page/service/nagasa-mai/detail/present/cup_beech_v0.1.1.jpg"></w-image>
        </w-flex>
        &emsp;<router-link to="/service/nagasa-mai/takeout/">お持ち帰りサービス</router-link>
        では、お越しになってくださった方への御礼として、手作りのマグカップをひとつプレゼントしております。
        房総半島のお土産のひとつにささやかながら加えていただけますと幸いです。
        <ul class="no-point">
          <li>※ その都度、いろいろなカップの形状となります</li>
          <li>※ 万が一、ご準備が間に合わない場合には、恐れ入りますが郵送でのお渡しとさせていただきます</li>
        </ul>
      </p>
      <h2 id="bad">
        <w-icon>mdi mdi-dots-hexagon</w-icon>
        斑点米 と 微小な小石
      </h2>
      <w-divider/>
      <p>
        &emsp;米農家で保管しているお米には、ごく稀に色の変わった斑点米や、微小な小石が含まれていることがあります。
        斑点米は稲穂の籾を虫が食べた後のひとまわり小さな米粒が、
        微小な小石は刈り取られた稲についていた田んぼの土の中に含まれる微小な小石が、
        最先端の農機具を通して処理した後でも、比重の関係でどうしても最後まで残ってしまうものがあるためです。
        <br>&emsp;ただし、我々 米農家は、時として斑点米や微小な小石が混じることのあるお米を、古来より毎日美味しくいただいており、
        昔と変わらぬ手法で育てているお米に対し、大きな<b>信頼</b>と<b>安心感</b>をもっております。
        特に、我が米農家では、ごく少量の斑点米があることは、<u>虫も食べるほどに美味しく、且つ虫を除去する農薬に頼りすぎていない、
        安心・安全な栽培方法である証拠</u>として、捉えております。
        これらの安心感が、どうかお客様に少しでも届けば、誠に嬉しく思います。
        <br>&emsp;どうしても、斑点米や微小な小石を完全に取り除きたい際は、
        お近くのコイン精米機で精米（１０キロ百円程度です）しますと、
        ほとんどきれいさっぱりと除去されますので、お試しいただければと思います。
        <br>&emsp;また、米屋の商品は、色彩選別機という機械を通して商品化しているため、
        色の変わった斑点米や微小な小石が非常に少ないものとなっています。しかし、色彩という名前からもわかるように、
        味とは関係のない目的のためのコスト（ひと粒ひと粒の写真を撮って真っ白でない米粒を除去するという機械の稼働）が、
        価格に上乗せされているということを、おわかりいただけると思います。
      </p>
      <h2 id="we">
        <w-icon>mdi mdi-face-man-shimmer-outline</w-icon>
        わたしたち
      </h2>
      <w-divider/>
      <p>
        <w-flex class="justify-space-evenly align-center">
          <w-image tag="img" width="100%" class="bdrs3" src="/image/page/service/nagasa-mai/detail/we/we_v0.2.0.jpg"></w-image>
        </w-flex>
        みーんな 生き物が大好きで、鯉・ウコッケイ・鰻なども育てていますよ
        <w-icon>mdi mdi-owl</w-icon>
      </p>
      <p class="center">
        <w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/takeout/">
          お持ち帰りサービス
          <w-icon>mdi mdi-car-hatchback</w-icon>
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>
